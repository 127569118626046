@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * ==============
 * Global
 * ==============
 */

.gradient-text {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

/**
 * ==============
 * Header
 * ==============
 */

.site-header {
    background: linear-gradient(to right, black, #6c0018);
}

/**
 * ==============
 * Footer
 * ==============
 */

.site-footer-inner {
    background-color: #6c0018;
    background-image: url("../assets/img/footer/background.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow:
        0px 0px 0px 0px #00000040,
        inset 0 0 0 1000px rgba(0, 0, 0, 0.9);
}
